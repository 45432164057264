@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
.nav-bg {
  background-color: transparent;
  height: 10vh;
  position: -webkit-sticky;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
}

.logo {
  margin-left: 1rem;
  text-decoration: none;
  font-size: 2rem;
  font-weight: 600;
}

.nav-link {
  text-decoration: none;
  font-size: 1rem;
  margin-right: 1.2rem;
}

.nav-link:hover {
  color: rgb(206, 206, 206);
}

.connect-wallet-btn {
  margin-right: 2rem;
  padding: 0.8rem 2rem;
  background-color: rgb(29, 29, 29);
  cursor: pointer;
  border: 1px solid white;
}

.connect-wallet-btn:hover {
  background-color: rgb(50, 50, 50);
}

.connected-wallet {
  margin-right: 2rem;
  padding: 0.8rem 2rem;
  background-color: rgb(29, 29, 29);
  color: gray;
  cursor: cursor;
  border: 1px solid rgb(58, 58, 58);
}

@media screen and (max-width: 380px) {
  .connect-wallet-btn {
    margin-right: 5px;
    padding: 0.5rem 0.2rem;
    font-size: 0.8rem;
  }
  .connected-wallet {
    margin-right: 5px;
    padding: 0.5rem 0.2rem;
    font-size: 0.8rem;
  }
  .nav-link {
    font-size: 1rem;
    margin-right: 0.6rem;
  }
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  /* background-color: rgb(29, 29, 29); */
  color: white;
  font-family: "Roboto", sans-serif;
}
body{
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1920 1080'%3E%3CradialGradient id='SVGID_1_' cx='1465.15' cy='268.7' r='575.6' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%238a0096' stop-opacity='.9'/%3E%3Cstop offset='1' stop-color='%238a0096' stop-opacity='0'/%3E%3C/radialGradient%3E%3Ccircle cx='1465.15' cy='268.7' r='575.6' fill='url(%23SVGID_1_)'/%3E%3CradialGradient id='SVGID_2_' cx='1002.24' cy='503.54' r='575.6' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%235b4ccc' stop-opacity='.7'/%3E%3Cstop offset='1' stop-color='%234d419d' stop-opacity='0'/%3E%3C/radialGradient%3E%3Ccircle cx='1002.24' cy='503.54' r='575.6' fill='url(%23SVGID_2_)'/%3E%3C/svg%3E")
  no-repeat #1b203d !important;
}

.MuiDialog-paperScrollPaper {
  background: linear-gradient(
    90deg,
    rgba(34, 60, 61, 1) 8%,
    rgba(38, 51, 83, 1) 48%,
    rgba(35, 37, 46, 1) 100%
  );
  boxshadow: -2px 3px 13px -1px rgba(0, 0, 0, 0.8);

  width: auto;
  height: auto;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px !important;
  padding: 4px;
  z-index: 10000000 !important;
}
.dialoge__content__section {
  background: linear-gradient(
    90deg,
    rgba(34, 60, 61, 1) 8%,
    rgba(38, 51, 83, 1) 48%,
    rgba(35, 37, 46, 1) 100%
  );
  border-radius: 30px;
  width: 100%;
  height: 100%;
  z-index: 10000000 !important;
}

@media screen and (max-width: 544px) {
  .MuiDialog-paperScrollPaper {
    width: 100%;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
#home-page {
  /* height: 90vh; */
  background-color: rgb(29, 29, 29) !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content-holder {
  display: flex;
  justify-content: space-between;
  width: 65%;
  margin-top: 4rem;
}

.text-box {
  width: 48%;
  padding: 1rem;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.heads {
  margin-bottom: 1rem;
  margin-left: 2rem;
}

.main-head {
  font-weight: 500;
  margin-bottom: 0.1rem;
}

span {
  font-weight: 600;
}

.sub-head {
  font-weight: 400;
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.text-tag {
  font-weight: 300;
  font-size: 0.8rem;
  margin-bottom: 1rem;
}

.your-address {
  font-weight: 300;
  margin-bottom: 1rem;
}

.input-field {
  margin-top: 1rem;
  background-color: transparent;
  outline: none;
  border: none;
  border-bottom: 1px solid white;
  font-size: 1rem;
  width: 100%;
  padding: 1rem;
  margin-bottom: 1rem;
}

.show-address {
  font-weight: 300;
  font-size: 0.8rem;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}

.buttons {
  margin-top: 0.5rem;
  display: flex;
  width: 100%;
  justify-content: center;
}

.btn {
  background-color: rgb(40, 40, 40);
  padding: 0.5rem 1rem;
  margin-right: 5rem;
  border: none;
  cursor: pointer;
}

.btn:hover {
  background-color: rgb(50, 50, 50);
}

.card {
  width: 48%;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
  padding: 4rem 2rem;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.144);
}

.card-container {
  height: 80%;
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
}

.staked-balance,
.rewards-unstake,
.rewards-maturity,
.early-withdraws,
.maturity {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 0.5rem;
  align-items: center;
}
.value-desc,
.text-desc {
  font-size: 1rem;
}

.text-desc {
  font-weight: 300;
}

.value-time {
  font-size: 0.7rem;
  font-weight: 500;
}

@media screen and (max-width: 950px) {
  .content-holder {
    width: 98%;
  }
}

@media screen and (max-width: 820px) {
  #home-page {
    height: 100%;
  }

  .content-holder {
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
  }

  .text-box {
    margin-bottom: 2rem;
    width: 90%;
  }

  .card {
    width: 90%;
  }
}

@media screen and (max-width: 460px) {
  .card {
    padding: 1rem 0.5rem;
  }

  .text-box {
    align-items: center;
  }

  .btn {
    margin: 10px;
  }
}




