.nav-bg {
  background-color: transparent;
  height: 10vh;
  position: -webkit-sticky;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
}

.logo {
  margin-left: 1rem;
  text-decoration: none;
  font-size: 2rem;
  font-weight: 600;
}

.nav-link {
  text-decoration: none;
  font-size: 1rem;
  margin-right: 1.2rem;
}

.nav-link:hover {
  color: rgb(206, 206, 206);
}

.connect-wallet-btn {
  margin-right: 2rem;
  padding: 0.8rem 2rem;
  background-color: rgb(29, 29, 29);
  cursor: pointer;
  border: 1px solid white;
}

.connect-wallet-btn:hover {
  background-color: rgb(50, 50, 50);
}

.connected-wallet {
  margin-right: 2rem;
  padding: 0.8rem 2rem;
  background-color: rgb(29, 29, 29);
  color: gray;
  cursor: cursor;
  border: 1px solid rgb(58, 58, 58);
}

@media screen and (max-width: 380px) {
  .connect-wallet-btn {
    margin-right: 5px;
    padding: 0.5rem 0.2rem;
    font-size: 0.8rem;
  }
  .connected-wallet {
    margin-right: 5px;
    padding: 0.5rem 0.2rem;
    font-size: 0.8rem;
  }
  .nav-link {
    font-size: 1rem;
    margin-right: 0.6rem;
  }
}
